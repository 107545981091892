.custom-navbar-container {
  padding: 20px 50px;
}

.challenges-card-with-bg:hover .card-description {
  display: block !important;
}

.challenges-card-with-bg::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #184d98;
  opacity: 0;
  transition: top 0.2s ease-out, opacity 0.5s ease-out;
}

.challenges-card-with-bg:hover::before {
  top: 0;
  opacity: 0.5;
}

.video-container {
  position: relative;
  width: 100%;
  height: 70vh;
  overflow: hidden;
}

.mobile-sub-image-page .heading-text {
  font-size: 38px;
}

.contact-us-form .ant-form-item-label {
  padding: 0px;
}

.contact-form-container {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.contact-us-card {
  margin-top: -200px !important;
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  transform: translate(-50%, -50%);
  background-size: cover;
}

.contact-us .contact-us-navbar-container {
  margin-top: 90px;
}

.reconised-image {
  width: -webkit-fill-available;
}

.custom-navbar-container .logo-img {
  width: 168px;
  height: 49px;
}

/* .home-heading-container {
 
  height: 100vh;
  width: 100%;
}

.home-heading-container video {
  object-fit: fill !important;
  height: 100vh;
} */


.home-heading-container {
  background: url('../images/bim/bimmainbgimg.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.home-heading-container .heading-container {
  display: flex;
  align-items: center;
}

.home-heading-container .main-heading {
  font-size: 64px;
  font-weight: 700;
}

.home-heading-container .heading-paragraph {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.05em;
}

.home-heading-container .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}

.bim-second-text-container{
  padding-left: 3rem;
  padding-right: 0.5rem;
}

.bim-consulting-form {
  /* background-color: #ecedf6; */
  background: url('../images/bim/bim_consulting-bg.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.home-container .challenges-card-container-bg {
  background: url('../images/challenges-container-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  padding-right: 10px !important;
  width: 100%;
  overflow: hidden;
}

.home-digital-information {
  background-image: url('../images/featureimg2.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  height: auto !important;
  width: 100%;
}

.home-heading-container .heading-container {
  display: flex;
  align-items: center;
}

.home-heading-container .aboutus-heading {
  font-size: 64px;
  font-weight: 700;
}

.home-heading-container .heading-paragraph {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.05em;
  width: 80%;
}

.home-heading-container .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  line-height: 22.68px;
  text-align: left;
}

.digital-transformation-heading-container {
  background: url('../images/Digital-Transformation.webp');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;

}

.digital-transformation-heading-container .heading-container {
  display: flex;
  align-items: center;
}

.digital-transformation-heading-container .digital-transformation-heading {
  font-size: 64px;
  font-weight: 700;
}

.digital-transformation-heading-container .heading-paragraph {
  font-size: 18px;
  font-weight: 400 !important;
}

.our-technologies-container .heading-paragraph {
  font-size: 20px;
  letter-spacing: normal;
}

.our-values .heading-paragraph {
  font-size: 20px;
  letter-spacing: normal;
}

.digital-transformation-heading-container .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}


.aboutus-heading-container {
  background: url('../images/aboutusPage/aboutus.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.aboutus-heading-container .heading-container {
  display: flex;
  align-items: center;
}

.aboutus-heading-container .aboutus-heading {
  font-size: 64px;
  font-weight: 700;
}

.aboutus-heading-container .heading-paragraph {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.05em;
}

.aboutus-container .our-story .heading-paragraph {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.05em;
}

.aboutus-heading-container .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}

.case-study .case-study-heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 51.27px;
  letter-spacing: 0.03em;
  text-align: center;
}

.case-study .heading-paragraph {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.03em;
  text-align: center;
}

.ai-heading-container {
  background: url('../images/AI/AIpagebanner.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.ai-heading-container .heading-container {
  display: flex;
  align-items: center;
}

.ai-heading-container .ai-heading {
  font-size: 64px;
  font-weight: 700;
}

.ai-heading-container .heading-paragraph {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.05em;
}

.ai-heading-container .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}

.dubai-heading-container {
  background: url('../images/Dubaiheadingbg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.header-drop-down-card .ant-card-body {
  padding: 0;
}

.section-contaioner {
  padding-right: 0px !important;
}

.services-container {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.services-container .big-text {
  font-size: 50px;
  font-weight: 700;
  line-height: 58.1px;
  letter-spacing: 0.01em;
}

.services-container .small-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 28.8px;
  letter-spacing: 0.03em;
}

.service-container .heading {
  font-size: 38px !important;
  line-height: 1.2em;
}

.service-container .card-paragraph {
  font-size: 16px;
  line-height: 1.5em;
  letter-spacing: 0em;
}

.service-container .card-heading {
  font-size: 24px;
}

.custom-main-bg-color {
  background-color: #184d98;
}

.custom-btn {
  border-color: #184d98 !important;
  border-width: 2px !important;
  color: #184d98;
}

.custom-step-icon {
  border-color: #184d98 !important;
  border-width: 4px !important;
  background-color: #ffffff;
}

.get-in-touch-btn {
  background-color: #184d98 !important;
  border-color: #184d98 !important;
  color: #ffffff;
}

.get-in-touch-btn:hover {
  background-color: #ffffff !important;
  border-color: #184d98 !important;
  color: #184d98 !important;
}

.cencle-btn {
  background-color: #ffffff !important;
  border-color: #184d98 !important;
  color: #184d98 !important;
}

.cencle-btn:hover {
  background-color: #184d98 !important;
  border-color: #184d98 !important;
  color: #ffffff !important;
}

.custom-btn:hover {
  background-color: #184d98 !important;
  color: #ffffff !important;
}

.our-story-year-div-right {
  background-color: #184d98 !important;
  color: #ffffff !important;
  padding: 5px 35px;
  margin-left: 175px;
  width: 230px;
}

.our-story-year-div-left {
  background-color: #184d98 !important;
  color: #ffffff !important;
  padding: 5px 35px;
  margin-right: 175px;
  width: 230px;
}

.industries-card {
  height: 100%;
}

.custom-color {
  color: #184d98 !important;
}

.divider-custom {
  border: 1px solid rgba(48, 48, 48, 0.5) !important;
}

.carousel-container {
  position: relative;
}

.arrow-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.prev-button,
.next-button {
  font-size: 20px;
}

.custome-background-color {
  background-color: #f3f8ff;
}

.digital-industries-carousal-container {
  padding-right: 0 !important;
}

.digital-industries-carousal-container .box {
  border-radius: 0px 0px 0px 65px;
}

.digital-industries-carousel .slick-dots-bottom {
  bottom: -18px !important;
  gap: 11px !important;
  z-index: 000;
}

.digital-industries-carousel .slick-dots li button::after {
  background: #184d98;
  border-radius: 100%;
  width: 11px;
}

.digital-industries-carousel .slick-dots li.slick-active {
  width: 0;
}

.digital-industries-carousel .slick-dots li {
  width: 0;
}

.ant-card-body {
  padding: 0 !important;
  margin: 0;
  height: 100% !important;
}

.blog-btn:hover {
  border-color: #184d98;
  background: #f3f8ff !important;
}

.learn-more-btn:hover {
  border-color: #184d98;
  background: #f3f8ff !important;
  color: #184d98 !important;
}

.custom-paragraph-color {
  color: rgb(96, 96, 96) !important;
}

.arrow-icon svg {
  width: 7em !important;
  height: 4em !important;
  color: gray;
}

.image-container {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  opacity: 0.5;
}

.active-img {
  opacity: unset;
  width: 150px;
  height: 150px;
  border: 4px solid #184d98;
}

.quote-img-container {
  bottom: -14%;
  left: 36%;
}

.quote-img-container img {
  opacity: 0.75;
}

.backgroud-img {
  background: url('../images/testimonial.png');
}

.frequently-question-collapse .ant-collapse-header {
  color: #363e3e;
  font: 18px / 1.2em Helvetica, sans-serif;
  font-weight: bold;
}

.frequently-question-collapse .ant-collapse-content-box p {
  font: 16px / 1.2em Helvetica, sans-serif;
  color: rgb(67, 67, 67);
  font-weight: normal;
  font-size: none;
}

.footer-company-dis {
  color: rgb(48, 48, 48);
}

.custom-font-family {
  font-family: 'helvetica w01 roman', 'helvetica w02 roman',
    'helvetica lt w10 roman', sans-serif;
}

.card-customize {
  min-width: 420px !important;
  max-width: 420px !important;
}

.feturing-container {
  padding-right: 140px !important;
}

/* .sub-item ul li {
  padding: 7px 16px;
  border-radius: 10px;
  text-decoration: underline;
  text-underline-position: under;
  cursor: pointer;
}

.sub-item ul li:hover {
  background-color: #f1f1f1;
}

.sub-item ul .selected {
  background-color: #f1f1f1;
} */

.sub-header-dropdown-menu-img {
  background: url('../images/headersubmenuimg.png');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  width: 100% !important;
  height: 100% !important;
}

.header-list ul li:hover {
  text-decoration: underline;
  text-underline-position: under;
}

.navbar-scroll-view .header-list ul .active img {
  filter: grayscale(100%) contrast(0);
}

.navbar-scroll-view .header-list ul img {
  filter: grayscale(100%) contrast(0);
}

.header-list ul li:hover img {
  transform: rotate(180deg);
}

.header-list ul li {
  cursor: pointer;
}

.header-list ul .active {
  text-decoration: underline;
  text-underline-position: under;
}

.custom-step .ant-steps-item-content {
  width: 100% !important;
  text-align: start !important;
  margin-left: 40px !important;
}

.custom-step .ant-steps-item-container .ant-steps-item-tail::after {
  height: 3px !important;
  background-color: #184d98 !important;
}

.custom-step .ant-steps-item-container .ant-steps-item-icon {
  margin-top: -18px;
  margin-left: 19px !important;
}

.background-color-light {
  background-color: rgb(250, 250, 250);
}

.tech-carousel .slick-dots {
  margin-bottom: -120px !important;
  z-index: 0;
}

.tech-carousel .slick-dots li.slick-active button {
  background: #184d98 !important;
}

.tech-carousel .slick-dots li button {
  background: #184d98 !important;
  opacity: 0.5;
}

.underline-bar-custom {
  width: 34px;
  height: 7px;
  background-color: #000000;
}

.mobile-app-development-card {
  width: 100%;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-top: -25px;
}

.ready-to-transform-bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #184d98;
  opacity: 0.75;
}

.ready-to-transform-bg {
  background: url(../images/querypagebg.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  min-height: 417px !important;
  z-index: 0;
  position: relative;
}

.consultation-container {
  background: url('../images/businessbg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  width: 100%;
  z-index: 0;
  position: relative;
}

.consultation-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  opacity: 0.6;
}

.consultation-container-mobile-devepment {
  background: url('../images/mobileappDevelopment/consulting-bging.webp');
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  width: 100%;
  z-index: 0;
  position: relative;
}

.consultation-container-mobile-devepment::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.3);
  opacity: 0.2;
}

.consultation-container-ai-devepment {
  background: url('../images/AI/aiconsultatingbgimg.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  width: 100%;
  z-index: 0;
  position: relative;
}

.consultation-container-ai-devepment::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.3);
  opacity: 0.2;
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ant-input::placeholder {
  color: black;
  font-family: Helvetica !important;
}

.comment-input .ant-input::placeholder {
  color: #363e3e;
  font-family: Helvetica !important;
}

.comment-input:focus {
  box-shadow: none !important;
}

.ant-input:hover {
  background-color: #ffffff;
}

.navbar-container {
  margin-top: 121px !important;
}

.dubai-main-section-container {
  padding-left: 42px;
}

.dubai-cunsultation-form-input:hover {
  background-color: #ffffff !important;
}

.talk-to-experts-btn {
  background-color: #e9464a;
  color: #f0f0f0;
}

.talk-to-experts-btn:hover {
  background-color: #e9464a !important;
  color: #ffffff !important;
}

.blog-filter-container ul li:hover {
  opacity: unset !important;
}

.blog-filter-container ul li {
  cursor: pointer;
  white-space: nowrap;
}

.blog-filter-container-div {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.search-bar {
  border-width: 1px;
  border-color: #e4e4e4;
  border-style: solid;
  border-radius: 21px;
  height: 46px;
}

.serach-bar .ant-input-group-addon .ant-btn {
  border-radius: 21px;
}

.search-bar .ant-input-wrapper .ant-input-affix-wrapper .ant-input {
  height: 43px !important;
  border: none !important;
  border-radius: 21px !important;
  font-family: 'helvetica w01 roman', 'helvetica w02 roman',
    'helvetica lt w10 roman', sans-serif !important;
}

.search-bar .ant-input-wrapper .ant-input-affix-wrapper {
  height: 43px !important;
  border: none !important;
  border-radius: 21px !important;
  font-family: 'helvetica w01 roman', 'helvetica w02 roman',
    'helvetica lt w10 roman', sans-serif !important;
  box-shadow: none;
  display: flex;
  align-items: center;
}

.search-bar .ant-input-wrapper .ant-input::placeholder {
  color: #9b9f9f;
}

.search-bar .ant-input-wrapper .ant-input:focus-within {
  box-shadow: none !important;
}

.search-bar .ant-input-group-addon {
  border-radius: 21px !important;
  height: 46px !important;
}

.search-bar .ant-input-group-addon .ant-btn {
  border-radius: 91px !important;
  height: 100% !important;
  padding: 0px 27px !important;
  background-color: #184d98;
  font-family: 'helvetica w01 roman', 'helvetica w02 roman',
    'helvetica lt w10 roman', sans-serif !important;
}

.blog-home-page-card {
  border-radius: 16px;
  padding: 29px 36px !important;
  border-color: #e4e4e4;
  height: auto;
  cursor: pointer;
}

.casestudy-card-container {
  background: #fafafa;
  box-shadow: 0px 0px 16.8px 2px #1818180d;
}

.casestudy-card-container .percentage-card {
  padding: 8px 14px;
  gap: 4px;
  border-radius: 2px 0px 0px 0px;
  border-left: 1px solid #184d98;
}

.casestudy-card-container .percentage-card .percentage {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.03em;
  text-align: left;
}

.casestudy-card-container .percentage-card .percentage-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-align: left;
}

.casestudy-card-container .heading-btn {
  font-size: 14px;
  padding: 16px 40px;
  border-radius: 50px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.1px;
}

.blog-home-page-card .blog-type {
  font-weight: 700 !important;
  font-size: 16px !important;
}

.blog-home-page-card .blog-card-title {
  font-weight: 800 !important;
  font-size: 28px !important;
}

.blog-home-page-card .blog-discription {
  font-weight: 400 !important;
  font-size: 16px !important;
}

.blog-card-title:hover {
  color: #184d98;
}

.blog-card-title {
  cursor: pointer;
}

.footer-container {
  padding-bottom: 0 !important;
}

.custom-pagination .ant-pagination-item-active {
  color: #000;
}

.custom-blog-scroll::-webkit-scrollbar {
  display: none;
}

.icon-container {
  background: rgba(115, 119, 156, 0.8);
  box-shadow: 0px 4.47px 111.8px 0px #0000000d;
}

.icon-container .icon-div {
  opacity: 1 !important;
}

.custom-black-color {
  color: #363e3e;
}

.challenges-card-container::-webkit-scrollbar {
  display: none;
}

.service-accprdian-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

.service-accprdian-scrollbar::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f5f5;
}

.service-accprdian-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e2e2e2;
}

.challenges-card {
  flex: 0 0 auto;
  width: 359px;
  height: 283px;
  border-radius: 12px;
  margin-right: 20px;
  box-shadow: 0px 4px 13.6px 2px #1818180d;
}
.challenges-card-with-img-home{
  flex: 0 0 auto;
  width: 366px;
  height: auto;
  border-radius: 12px;
  margin-right: 35px;
  box-shadow: 0px 4px 13.6px 2px #1818180d;
  overflow: hidden;
}
.challenges-card-with-img {
  flex: 0 0 auto;
  width: 400px;
  height: auto;
  border-radius: 12px;
  margin-right: 20px;
  box-shadow: 0px 4px 13.6px 2px #1818180d;
}
.challenges-card-with-img-sample-work {
  flex: 0 0 auto;
  width: 400px;
  height: auto;
  border-radius: 12px;
  margin-right: 20px;
  box-shadow: 0px 4px 13.6px 2px #1818180d;
}

.challenges-card-with-bg {
  flex: 0 0 auto;
  width: 290px;
  height: auto;
  border-radius: 12px;
  margin-right: 20px;
  box-shadow: 0px 4px 13.6px 2px #1818180d;
}

.challenges-card .header-container {
  background: linear-gradient(358.27deg,
      #ecfdff -3.65%,
      #ffffff 105.04%,
      #e1fcff 113.64%);
}

.about-us-artical-div-container {
  padding: 50px 200px;
}

.about-us-artical-div-container .about-us-artical-div {
  stroke: #303030;
  stroke-opacity: var(1);
  stroke-width: var(0);
  background-color: #f0f0f0;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 10px;
  padding: 30px 40px;
}

.our-technologies-container {
  background: url('../images/aboutusPage/ourTechnologies-aboutus.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  width: 100%;
}

.aboutus-container .technology-logo-box {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  padding: 10px 30px;
  width: 100px;
  height: 100px;
}

.aboutus-container .line-left {
  position: absolute;
  border-color: black;
  height: 80%;
  left: 35%;
  margin-top: 3px;
  border-width: 2px;
}

.aboutus-container .line-right {
  position: absolute;
  border-color: black;
  height: 80%;
  right: 35%;
  margin-top: 3px;
  border-width: 2px;
}

.divider-line {
  border-color: #184d98;
  border-inline-start-width: 3px !important;
}

.divider-line-visible {
  border-color: #184d98;
  border-inline-start-width: 3px !important;
}

.our-office-bg-image {
  position: relative;
  width: 100%;
}

.our-office-bg-image::before {
  content: '';
  background: url('../images/aboutusPage/our-office.jpg');
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

.our-office-bg-image .content {
  position: relative;
}

.mobile-app-development-heading-container {
  background: url('../images/mobileappdevHropic.webp');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.mobile-app-development-heading-container .heading-container {
  display: flex;
  align-items: center;
}

.mobile-app-development-heading-container .main-heading {
  font-size: 64px;
  font-weight: 700;
}

.mobile-app-development-heading-container .heading-paragraph {
  font-size: 18px;
  font-weight: 400 !important;

}

.mobile-app-development-heading-container .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}

.web-development-heading-container {
  background: url('../images/webdevelopment/webDevelopmentheroimg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  background-position: 100% -50px;
}

.web-development-heading-container .heading-container {
  display: flex;
  align-items: center;
}

.web-development-heading-container .main-heading {
  font-size: 64px;
  font-weight: 700;
}

.web-development-heading-container .heading-paragraph {
  font-size: 18px;
  font-weight: 400 !important;
}

.web-development-heading-container .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}

.mobile-app-development-tab .ant-tabs-tab {
  padding: 12px 12px !important;
}

.tag-list {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  gap: 1rem 0;
  position: relative;
  padding: 1.5rem 0;
  overflow: hidden;
}

.loop-slider {
  .inner {
    display: flex;
    width: fit-content;
    animation-name: loop;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: var(--direction);
    animation-duration: var(--duration);
  }
}

.tag {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

@keyframes loop {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

.cloud-development-heading-container {
  background: url('../images/Cloud/CloudComputingbanner.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  background-position: 0px -90px;
}

.cloud-development-heading-container .heading-container {
  display: flex;
  align-items: center;
}

.cloud-development-heading-container .main-heading {
  font-size: 64px;
  font-weight: 700;
}

.cloud-development-heading-container .heading-paragraph {
  font-size: 18px;
  font-weight: 400 !important;
}

.cloud-development-heading-container .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}

.consultation-container-cloud-devepment {
  background: url('../images/Cloud/saas-concept.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  width: 100%;
  z-index: 0;
  position: relative;
}

.consultation-container-cloud-devepment::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.3);
  opacity: 0.2;
}

.home-consulting-form {
  background-color: #ecedf6;
  background-image: url('../images/chicago-city-urban-skyline\ 1\ \(1\).png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.gradient-color {
  background: linear-gradient(179.85deg,
      rgba(171, 218, 220, 0.9) 30.74%,
      rgba(173, 173, 173, 0.9) 38%,
      rgba(43, 129, 235, 0.9) 56.79%,
      rgba(179, 171, 209, 0.9) 74.68%,
      rgba(135, 23, 214, 0.9) 91.18%,
      rgba(169, 55, 199, 0.9) 105.99%,
      rgba(210, 55, 231, 0.9) 50.55%,
      rgba(203, 93, 214, 0.9) 70.41%);
  background-clip: text;
  color: transparent;
}

.challenges-card-container .challenges-card-with-img {
  overflow: hidden;
}

.challenges-card-container .background-design {
  position: absolute;
  top: -50px;
  right: -50px;
}

.percentage-card-right {
  position: absolute;
  right: 0;
  top: 0;
}

.percentage-card-left {
  position: absolute;
  left: 0;
  top: 0;
}

.consulting-design {
  position: absolute;
  right: -25px;
  bottom: -25px;
}

.challenges-card-with-img .ant-card-actions {
  border: none !important;
  padding: 10px !important;
}

.custom-skin-bg-color {
  background-color: #fff0e8;
}

.custom-skin-color {
  color: #e3742d;
}


.client-review-container .client-card {
  box-shadow: 0px 4px 12px 2px #0000000A;
  background-color: #fff;
}

.healthcare-heading-container {
  background: url('../images/healthcare/healthcareherobg.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  background-position: 0px -90px;
}

.healthcare-heading-container .heading-container {
  display: flex;
  align-items: center;
}

.healthcare-heading-container .main-heading {
  font-size: 64px;
  font-weight: 700;
}

.healthcare-heading-container .heading-paragraph {
  font-size: 18px;
  font-weight: 400 !important;
}

.healthcare-heading-container .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}

.consultation-container-healthcare {
  background: url('../images/healthcare/healthcareconsultingbg.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  width: 100%;
  z-index: 0;
  position: relative;
}

.consultation-container-healthcare::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.3);
  opacity: 0.2;
}

.support-heading-container {
  background: url('../images/support/supportbg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  background-position: 100% -50px;
}

.support-heading-container .subheading {
  font-size: 20px;
}

.support-heading-container .heading-container {
  display: flex;
  align-items: center;
}

.support-heading-container .main-heading {
  font-size: 64px;
  font-weight: 700;
}

.support-heading-container .heading-paragraph {
  font-size: 18px;
  font-weight: 400 !important;
}

.support-heading-container .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}

input {
  font-family: Helvetica !important;
}

.nav-dropdown .ant-dropdown-menu-item {
  font-weight: 600 !important;
  color: #00000090 !important;
}

.nav-dropdown .ant-dropdown-menu-item:hover {
  color: #363e3e !important;
}

.nav-dropdown .ant-dropdown-menu-item-selected {
  background-color: lightgray !important;
  color: #363e3e !important;
  font-weight: 600 !important;
}

.stages-mobile-app ul {
  padding-left: 1rem !important;
}

.consultation-container-digital-transform {
  background: url('../images/digital-transformation/digitalconsultingbg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  width: 100%;
  z-index: 0;
  position: relative;
}

.consultation-container-digital-transform::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.3);
  opacity: 0.2;
}


.left-quote {
  position: absolute;
  left: -35px;
  top: 6px;
}

.responsive-quote {
  position: absolute;
  top: -16px;

}

/* .right-quote{
  position: absolute;
  bottom: 4px;
  right: 35%;
} */


.staffing-card .ant-card-actions {
  border: 0 !important;
}

.staffing-img-box {
  box-shadow: 0px 4px 12px 2px #0000000A;
}

.footer-list ul li:hover {
  text-decoration: underline;
}

.footer-list ul li {
  cursor: pointer;
}


.case-study .ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
}


.horizontal-dash-line::after {
  border-block-start-width: 3px !important;
  border-color: #E4E4E4;
}

.horizontal-dash-line::before {
  border-block-start-width: 3px !important;
  border-color: #E4E4E4;
}

.vertical-dash-line {
  border-inline-start-width: 3px !important;
  border-color: #E4E4E4;
}

.our-process-container img {
  filter: brightness(0) saturate(100%);

}

.breadcrumb-case-study li:last-child {
  color: #ffffff;
  text-decoration: underline;
}

.breadcrumb-case-study .ant-breadcrumb-separator {
  color: #ffffff;
}

.custom-background-color {
  background-color: #F3F8FF;
}

.contact-us-form .ant-form-item-explain-error {
  text-align: start;
}


.retail-heading-container {
  background: url('../images/retail/Retail\ Image\ Hero\ Section.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  background-position: 100% 0px;
}

.retail-heading-container .subheading {
  font-size: 20px;
}

.retail-heading-container .heading-container {
  /* padding-top: 130px !important; */
  display: flex;
  align-items: center;
}

.retail-heading-container .main-heading {
  font-size: 64px;
  font-weight: 700;
  /* line-height: 73.59px; */
}

.retail-heading-container .heading-paragraph {
  font-size: 18px;
  font-weight: 400 !important;
  /* letter-spacing: 0.05em; */
  /* line-height: 26px; */
}

.retail-heading-container .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}

.case-study .ant-card-actions {
  border: 0;
}


.consultation-container-retail {
  background: url('../images/retail/Retail\ Page\ Botton\ Contact\ Us\ Photo.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  width: 100%;
  z-index: 0;
  position: relative;
}

.consultation-container-retail::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.3);
  opacity: 0.2;
}


.education-heading-container {
  background: url('../images/education/Education\ BG\ 1.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  background-position: 0px;
}

.education-heading-container .heading-container {
  /* padding-top: 130px !important; */
  display: flex;
  align-items: center;
}

.education-heading-container .main-heading {
  font-size: 64px;
  font-weight: 700;
  /* line-height: 73.59px; */
}

.education-heading-container .heading-paragraph {
  font-size: 18px;
  font-weight: 400 !important;
  /* letter-spacing: 0.05em; */
  /* line-height: 26px; */
}

.education-heading-container .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}



::-webkit-scrollbar {
  display: none;
}


.case-study-card .challenges-card-with-img {
  flex: 0 0 auto;
  width: 400px;
  height: auto;
  border-radius: 12px;
  margin-right: 20px;
  /* Adjust as needed */
  box-shadow: 0px 4px 13.6px 2px #1818180d;
}

.custom-scroll-case-study-detail {
  max-height: 100vh;
}


.ai-warehouse-case-study {
  background: url('../images/casestudy/aifullsize.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.ai-warehouse-case-study .heading-container {
  padding-top: 130px !important;
}

.ai-warehouse-case-study .aboutus-heading {
  font-size: 64px;
  font-weight: 700;
}

.ai-warehouse-case-study .heading-paragraph {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.05em;
}


.ai-warehouse-case-study .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}

.b2bcollaboration-case-study {
  background: url('../images/casestudy/b2bfullsize.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.b2bcollaboration-case-study .heading-container {
  padding-top: 130px !important;
}

.b2bcollaboration-case-study .aboutus-heading {
  font-size: 64px;
  font-weight: 700;
}

.b2bcollaboration-case-study .heading-paragraph {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.05em;
}


.b2bcollaboration-case-study .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}

.intigrating-tool-casestudy {
  background: url('../images/casestudy/intigrationtoolfullsize.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.intigrating-tool-casestudy .heading-container {
  padding-top: 130px !important;
}

.intigrating-tool-casestudy .aboutus-heading {
  font-size: 64px;
  font-weight: 700;
}

.intigrating-tool-casestudy .heading-paragraph {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.05em;
}


.intigrating-tool-casestudy .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}


.escrow-casestudy {
  background: url('../images/casestudy/Frame\ 1000001968.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.escrow-casestudy .heading-container {
  padding-top: 130px !important;
}

.escrow-casestudy .aboutus-heading {
  font-size: 64px;
  font-weight: 700;
}

.escrow-casestudy .heading-paragraph {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.05em;
}


.escrow-casestudy .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}

.forex-currency-casestudy {
  background: url('../images/casestudy/forexcurrencyfullsize.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.forex-currency-casestudy .heading-container {
  padding-top: 130px !important;
}

.forex-currency-casestudy .aboutus-heading {
  font-size: 64px;
  font-weight: 700;
}

.forex-currency-casestudy .heading-paragraph {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.05em;
}


.forex-currency-casestudy .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}

.virtual-coin-casestudy {
  background: url('../images/casestudy/virtualcoinfullsize.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.virtual-coin-casestudy .heading-container {
  padding-top: 130px !important;
}

.virtual-coin-casestudy .aboutus-heading {
  font-size: 64px;
  font-weight: 700;
}

.virtual-coin-casestudy .heading-paragraph {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.05em;
}


.virtual-coin-casestudy .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}

.sub-item ul li:hover {
  opacity: 100% !important;
}

.sub-item ul li {
  cursor: pointer;
}


.iot-heading-container {
  background: url('../images/iot/iotmainbgimg.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.iot-heading-container .heading-container {
  /* padding-top: 130px !important; */
  display: flex;
  align-items: center;
}

.iot-heading-container .ai-heading {
  font-size: 64px;
  font-weight: 700;
}

.iot-heading-container .heading-paragraph {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.05em;
}

.iot-heading-container .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}

.iot-container .services .contant {
  border: 2px solid #E4E4E4
}

.iot-container .services ul li:hover {
  background-color: rgba(248, 249, 250, 1) !important;
}

.icon-box {
  background: linear-gradient(180deg, #184D98 0%, #3C7CD7 100%);

}

.iot-our-process .ant-row {
  display: flex;
  flex-wrap: wrap;
}

.iot-our-process .ant-row .custom-col {
  flex: 1 1 20%;
}

.iot-container .challenges-card-with-img {
  flex: 0 0 auto;
  width: 350px;
  height: auto;
  border-radius: 12px;
  margin-right: 20px;
  /* Adjust as needed */
  box-shadow: 0px 4px 13.6px 2px #1818180d;
}

.edi-heading-container {
  background: url('../images/edi/edimainbgimg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.edi-heading-container .heading-container {
  /* padding-top: 130px !important; */
  display: flex;
  align-items: center;
}

.edi-heading-container .ai-heading {
  font-size: 64px;
  font-weight: 700;
}

.edi-heading-container .heading-paragraph {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.05em;
}

.edi-heading-container .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}

.border-line {
  border-color: #184d98 !important;
  border-width: 3px !important;
}

.iot-container .ant-collapse-header {
  display: flex;
  align-items: center !important;
}

.consulting-heading-container {
  background: url('../images/consulting/consultingmainimg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.consulting-heading-container .heading-container {
  /* padding-top: 130px !important; */
  display: flex;
  align-items: center;
}

.consulting-heading-container .ai-heading {
  font-size: 64px;
  font-weight: 700;
}

.consulting-heading-container .heading-paragraph {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.05em;
}

.consulting-heading-container .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}

.consulting-container .consultation-consulting-container {
  background: url('../images/consulting/consultingsubimg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  width: 100%;
  z-index: 0;
  position: relative;
}




/* .text-container {
  text-align: center;
  position: relative;
}

.highlight {
  position: relative;
  color: #007bff;
}

.underline {
  position: absolute;
  bottom: -10px; 
  left: 0;
  right: 0;
  margin: auto;
} */

.workshop-heading-container {
  background: url('../images/workshop/workshopmainbgimg.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.workshop-heading-container .heading-container {
  padding-top: 130px !important;
  display: flex;
  align-items: center;
}

.workshop-heading-container .main-heading {
  font-size: 64px;
  font-weight: 400;
}



.workshop-heading-container .text-with-background{
padding: 19px 25px 19px 25px;
gap: 10px;
opacity: 0px;
background: rgba(44, 44, 44, 1);
font-size: 16px;
font-weight: 700;
line-height: 18.4px;
letter-spacing: 0.12em;
text-align: left;
}


.workshop-heading-container .highlight {
  position: relative;
  color: rgba(0, 146, 255, 1);
  font-weight: 700;
}

.workshop-heading-container .underline {
  position: absolute;
  bottom: -10px; 
  left: 0;
  right: 0;
  margin: auto;
}

.workshop-image{
  margin-left: -20px;
  margin-top: -15px;
}

.other-workshop .ant-card-actions{
  border: none;
}
.other-workshop .ant-card-actions li{
  margin: 0;
  margin-top: 15px;
}

.media-heading-container {
  background: url('../images/media/mediamainbgimg.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  background-position: 0px;
}

.media-heading-container .heading-container {
  /* padding-top: 130px !important; */
  display: flex;
  align-items: center;
}

.media-heading-container .main-heading {
  font-size: 64px;
  font-weight: 700;
  /* line-height: 73.59px; */
}

.media-heading-container .heading-paragraph {
  font-size: 18px;
  font-weight: 400 !important;
  /* letter-spacing: 0.05em; */
  /* line-height: 26px; */
}

.media-heading-container .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}

.media-consulting-container {
  background: url('../images/consulting/consultingsubimg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  width: 100%;
  z-index: 0;
  position: relative;
}


.bim-heading-container {
  background: url('../images/bim/bimmainbgimg.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.bim-heading-container .heading-container {
  display: flex;
  align-items: center;
}

.bim-heading-container .main-heading {
  font-size: 64px;
  font-weight: 700;
}

.bim-heading-container .heading-paragraph {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.05em;
}

.bim-heading-container .heading-btn {
  padding: 16px 40px 16px 40px;
  gap: 10px;
  box-shadow: 4px 4px 30px 0px #00000008;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
}

.bim-second-text-container{
  padding-left: 3rem;
  padding-right: 0.5rem;
}

.bim-consulting-form {
  /* background-color: #ecedf6; */
  background: url('../images/bim/bim_consulting-bg.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.home-services{
  padding-top: 10vh !important;
  padding-bottom: 10vh !important;
}

.SITE_HEADER {
  display: none;
}

.float-label {
  position: relative;
}

.label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 11px;
  transition: 0.2s ease all;
  font-family:  Helvetica, sans-serif;
  font-weight: 500;
}

.as-placeholder {
  color: gray;
}

.as-label {
  top: -8px;
  font-size: 12px !important;
  background: white;
  padding: 0 4px;
  margin-left: -4px;
}