body {
  margin: 0;
  font-family: Helvetica !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0em;
  color: #363E3E !important;

}

code {
  font-family: Helvetica !important;
  color: #363E3E !important;
}

.custom-font {
  font-family: Helvetica !important;
  font-display: swap;
}

.custom-roman-font {
  font-family: Helvetica  !important;
}