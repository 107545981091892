@media only screen and (min-width: 576px) and (max-width: 767px) {
    .consulting-design{display: none;}
    .mobile-sub-image-page .heading-text {
        font-size: 28px;
    }

    .service-container .heading {
        font-size: 24px !important;
    }

    .service-container .card-paragraph {
        font-size: 16px !important;
    }

    .service-container .card-heading {
        font-size: 24px !important;
    }

    .tech-carousel .slick-dots {
        margin-bottom: -90px !important;
        z-index: 0;
    }

    .about-us-artical-div-container {
        padding: 50px 70px;
    }

    .about-us-artical-div-container .about-us-artical-div {
        flex-direction: column;
        gap: 15px;
        text-align: center;
    }

    .aboutus-container .line-left {

        left: 26%;
    }

    .aboutus-container .line-right {
        right: 26%;
    }

    .card-customize {
        min-width: auto !important;
        max-width: auto !important;
    }

    .feturing-container {
        padding-right: 20px !important;
    }

    .ready-to-transform-bg {
        flex-direction: column-reverse;
    }

    .heading-searchbar-container {
        display: flex;
        flex-direction: column-reverse;
    }

    .blog-home-page-card {
        padding: 20px 25px !important;
    }

    .digital-transformation-heading-container {
        background-size: cover;
        height: auto !important;
        min-height: auto;
        width: 100%;
    }

    .digital-transformation-heading-container .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }

    .digital-transformation-heading-container .digital-transformation-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .digital-transformation-heading-container .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
        width: 85%;
    }

    .our-technologies-container .heading-paragraph {
        font-size: 12px;
        letter-spacing: normal;
    }

    .our-values .heading-paragraph {
        font-size: 12px;
        letter-spacing: normal;
    }

    .digital-transformation-heading-container .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }


    .home-heading-container {
        /* background-size: cover; */
        height: auto !important;
        min-height: auto;
        width: 100%;

    }
    .home-heading-container video{
        object-fit: cover;
        height: 50vh;
        }

    .home-heading-container .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }

    .home-heading-container .aboutus-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .home-heading-container .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
    }

    .home-heading-container .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }





    .aboutus-heading-container {
        background-size: cover;
        height: auto !important;
        min-height: auto;
        width: 100%;
    }

    .aboutus-heading-container .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }

    .aboutus-heading-container .aboutus-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .aboutus-heading-container .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
    }

    .aboutus-heading-container .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }



    .dubai-heading-container {
        background-size: cover;
        height: auto !important;
        min-height: auto;
        width: 100%;
    }

    .ai-heading-container {
        background-size: cover;
        height: auto !important;
        min-height: auto;
        width: 100%;
    }

    .ai-heading-container .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }

    .ai-heading-container .ai-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .ai-heading-container .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
    }

    .ai-heading-container .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }


    .custom-navbar-container {
        padding: 25px 20px;
    }

    .custom-navbar-container .logo-img {
        width: 111px;
        height: 34px;
    }

    .digital-industries-carousel .slick-dots-bottom {
        bottom: 3px !important;
    }

    .case-study .case-study-heading {
        font-size: 30px;
        line-height: 40.6px;
    }

    .case-study .heading-paragraph {
        font-size: 14px;
        line-height: 23.4px;
    }

    .case-study .casestudy-card-container img {
        border-radius: 10px;
    }

    .our-story .story-container .story-heading {
        text-align: center;
    }

    .our-story .story-container .story-time {
        flex-direction: column !important;
    }

    .our-story .story-container .story-time .divider-line-visible {
        height: 55px !important;
    }

    .our-story .story-container {
        gap: 50px;
    }

    .our-story .story-container .story-time .divider-line {
        display: none;
    }

    .our-story .story-container .story-time .our-story-year-div-right {
        position: static !important;
        margin: 0;
        align-items: center !important;
    }

    .our-story .story-container .story-time .our-story-year-div-left {
        position: static !important;
        margin: 0;
        align-items: center !important;
    }

    .our-story .story-container .story-img {
        justify-content: center !important;
    }

    .our-story .story-container .story-img img {
        width: 200px !important;
    }

    .our-story .story-container .story-time-last {
        align-items: center !important;
    }

    .our-story .story-container .story-img img {
        width: 180px !important;
    }

    .our-office-bg-image {
        text-align: center;
        justify-content: center;
        display: flex;
    }

    .our-office-bg-image .content .heading {
        align-items: center;
    }

    .contact-us-card {
        margin-top: 0px !important;
    }

    .contact-us-card .contact-form-container {
        width: 100% !important;
    }

    .contact-form-container {
        box-shadow: none
    }

    .video-container {
        height: 35vh;
        overflow: visible;
    }

    .case-study-heading-container {
        margin-top: 63px !important;
    }

    .contact-us-navbar-container {
        margin-top: 53px !important;
    }

    .mobile-app-development-heading-container {
        background-size: cover;
        height: auto !important;
        min-height: auto;
        width: 100%;
    }

    .mobile-app-development-heading-container .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }

    .mobile-app-development-heading-container .main-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .mobile-app-development-heading-container .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
        width: 85%;
    }

    .mobile-app-development-heading-container .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }

    .cloud-development-heading-container {
        background-size: cover;
        height: auto !important;
        min-height: auto;
        width: 100%;
        background-position: 0;
    }

    .cloud-development-heading-container .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }

    .cloud-development-heading-container .main-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .cloud-development-heading-container .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
        width: 85%;
    }

    .cloud-development-heading-container .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }

    .web-development-heading-container .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }

    .web-development-heading-container .main-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .web-development-heading-container .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
        width: 85%;
    }

    .web-development-heading-container .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }
    .support-heading-container .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }
    .support-heading-container .subheading{
        font-size: 18px;
      }
    .support-heading-container .main-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .support-heading-container .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
        width: 85%;
    }

    .support-heading-container .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }

    .healthcare-heading-container {
        background-size: cover;
        height: auto !important;
        min-height: auto;
        width: 100%;
    }

    .healthcare-heading-container .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }

    .healthcare-heading-container .main-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .healthcare-heading-container .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
        width: 85%;
    }

    .healthcare-heading-container .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }
    .home-container .challenges-card-container-bg{
        padding-right: 20px !important;
    }
    .horizontal-dash-line::after{
        border-block-start-width: 0px !important;
        border-color: #E4E4E4;
      }
      .horizontal-dash-line::before{
        border-block-start-width: 0px !important;
        border-color: #E4E4E4;
      }


      .retail-heading-container .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }
    .retail-heading-container .subheading{
        font-size: 18px;
      }
    .retail-heading-container .main-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .retail-heading-container .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
        width: 85%;
    }

    .retail-heading-container .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }
    .education-heading-container {
        background-size: cover;
        height: auto !important;
        min-height: auto;
        width: 100%;
    }

    .education-heading-container .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }

    .education-heading-container .main-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .education-heading-container .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
        width: 85%;
    }

    .education-heading-container .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }


    .ai-warehouse-case-study {
        background-size: cover;
        height: auto !important;
        min-height: auto;
        width: 100%;
    }

    .ai-warehouse-case-study .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }

    .ai-warehouse-case-study .aboutus-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .ai-warehouse-case-study .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
    }

    .ai-warehouse-case-study .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }

      .b2bcollaboration-case-study {
        background-size: cover;
        height: auto !important;
        min-height: auto;
        width: 100%;
    }

    .b2bcollaboration-case-study .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }

    .b2bcollaboration-case-study .aboutus-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .b2bcollaboration-case-study .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
    }

    .b2bcollaboration-case-study .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }

      .intigrating-tool-casestudy {
        background-size: cover;
        height: auto !important;
        min-height: auto;
        width: 100%;
    }

    .intigrating-tool-casestudy .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }

    .intigrating-tool-casestudy .aboutus-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .intigrating-tool-casestudy .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
    }

    .intigrating-tool-casestudy .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }
      .escrow-casestudy {
        background-size: cover;
        height: auto !important;
        min-height: auto;
        width: 100%;
    }

    .escrow-casestudy .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }

    .escrow-casestudy .aboutus-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .escrow-casestudy .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
    }

    .escrow-casestudy .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }

      .forex-currency-casestudy {
        background-size: cover;
        height: auto !important;
        min-height: auto;
        width: 100%;
    }

    .forex-currency-casestudy .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }

    .forex-currency-casestudy .aboutus-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .forex-currency-casestudy .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
    }

    .forex-currency-casestudy .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }

      .virtual-coin-casestudy {
        background-size: cover;
        height: auto !important;
        min-height: auto;
        width: 100%;
    }

    .virtual-coin-casestudy .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }

    .virtual-coin-casestudy .aboutus-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .virtual-coin-casestudy .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
    }

    .virtual-coin-casestudy .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }

    .iot-heading-container {
        background-size: cover;
        height: auto !important;
        min-height: auto;
        width: 100%;
    }

    .iot-heading-container .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }

    .iot-heading-container .ai-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .iot-heading-container .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
    }

    .iot-heading-container .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }
    .iot-our-process .ant-row .custom-col{
        flex: 1 1 50%;
     }

     .edi-heading-container {
        background-size: cover;
        height: auto !important;
        min-height: auto;
        width: 100%;
    }

    .edi-heading-container .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }

    .edi-heading-container .ai-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .edi-heading-container .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
    }

    .edi-heading-container .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }


    .consulting-heading-container {
        background-size: cover;
        height: auto !important;
        min-height: auto;
        width: 100%;
    }

    .consulting-heading-container .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }

    .consulting-heading-container .ai-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .consulting-heading-container .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
    }

    .consulting-heading-container .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }



    .workshop-heading-container {
        background-size: cover;
        height: auto !important;
        min-height: auto;
        width: 100%;
    }

    .workshop-heading-container .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }

    .workshop-heading-container .main-heading {
        font-size: 38px;
        font-weight: 400;
    }

    .workshop-heading-container .text-with-background {
        padding: 10px 16px 10px 16px;
        gap: 4.24px;
        opacity: 0px;
        font-size: 12px;
    }
    .media-heading-container {
        background-size: cover;
        height: auto !important;
        min-height: auto;
        width: 100%;
    }

    .media-heading-container .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }

    .media-heading-container .main-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .media-heading-container .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
        width: 85%;
    }

    .media-heading-container .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }
    .bim-heading-container {
        background-size: cover;
        height: auto !important;
        min-height: auto;
        width: 100%;
    }

    .bim-heading-container .heading-container {
        padding-left: 0px;
        padding-top: 90px !important;
    }

    .bim-heading-container .main-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .bim-heading-container .heading-paragraph {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
    }

    .bim-heading-container .heading-btn {
        padding: 8px 20px 8px 20px;
        gap: 10px;
        border-radius: 50px;
        font-size: 12px;

    }
    .bim-second-text-container{
        padding-left: 0rem;
        padding-right: 0rem;
      }

}